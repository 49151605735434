import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import gallery1 from '../images/gallery1.png';
import gallery2 from '../images/gallery2.png';
import gallery3 from '../images/gallery3.png';
import gallery4 from '../images/gallery4.png';
import gallery5 from '../images/gallery5.png';
import gallery6 from '../images/gallery6.png';
import gallery7 from '../images/gallery7.png';
import gallery8 from '../images/gallery8.png';
import gallery9 from '../images/gallery9.png';

import './gallery.css';

const images = [
    { src: gallery1, alt: "Emerald smiling in front of the Ruks Museum" },
    { src: gallery2, alt: "Emerald smiling in front of an old European castle" },
    { src: gallery3, alt: "Emerald wearing a scarf" },
    { src: gallery4, alt: "Emerald Lessley headshot" },
    { src: gallery5, alt: "Emerald pushing a wheelbarrow of flowers and smiling" },
    { src: gallery6, alt: "Emerald standing smiling by a large red letter 'I'" },
    { src: gallery7, alt: "Emerald Lessley headshot" },
    { src: gallery8, alt: "Emerald in front of an old building with blue domes" },
    { src: gallery9, alt: "Emerald Lessley headshot" },
];

const Gallery = () =>
    <Layout>
        <SEO title="Gallery" />
        <div className="gallery">
            {images.map(({ src, alt }) =>
                <img key={src} src={src} className="gallery-image" alt={alt} />
            )}
        </div>
    </Layout>

export default Gallery;
